<template>
  <div class="my-5 p-5 text-center">
    <img src="../assets/images/reservation-success.png" alt="" />
    <h5>{{ $t('listing.bookingRequestSent') }}</h5>
    <!-- <div class="my-3 small">
      A confirmation mail has been sent to your email address:
      test.user@example.com
    </div> -->
    <!-- <div class="my-4">Booking ID: 63527678244</div> -->
    <div class="mt-3">
      <a href="/listing" class="btn btn-outline-main">{{ $t('listing.backToListing') }}</a>
    </div>
    <!-- <div class="mt-3 mb-2">
      <div>Total Price:</div>
      <div>EUR 135.00</div>
      <div class="small">(VAT included)</div>
    </div>
    <div>EUR 135.00</div> -->
  </div>
</template>

<script>
export default {
  name: "ReservationSuccess",
};
</script>
